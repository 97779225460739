<template>
  <el-select
    name="company"
    v-model="inputValue"
    filterable
    clearable
    :disabled="disabled"
    size="large"
    placeholder="Select a company"
    class="w-100"
    @clear="$emit('clear')"
  >
    <el-option
      @click="updateValue(item.id)"
      v-for="item in companies"
      :key="item.id"
      :label="item.label"
      :value="item.value"
    >
    </el-option>
  </el-select>
</template>

<script>
import { useField } from "vee-validate";
import store from "@/store/index";
import { computed } from "vue";
import { mapActions } from "vuex";

export default {
  props: {
    initialValue: {
      type: [Number, String]
    },
    name: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      defaults: false
    }
  },
  methods: {
    ...mapActions("ListsModule", ["fetchCompaniesAll"])
  },
  mounted() {
    this.fetchCompaniesAll();
  },
  watch: {
    initialValue() {
      this.inputValue = this.initialValue;
    }
  },
  setup(props, { emit }) {
    const companies = computed(() => store.getters["ListsModule/companies"]);
    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta
    } = useField(props.name, undefined, {
      initialValue: props.initialValue
    });
    const updateValue = () => {
      emit("change", inputValue.value);
    };
    return {
      updateValue,
      handleChange,
      handleBlur,
      errorMessage,
      inputValue,
      meta,
      companies
    };
  }
};
</script>
