import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_Field = _resolveComponent("Field")!

  return (_openBlock(), _createBlock(_component_Field, {
    name: "branches",
    ref: "field"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_select, {
        name: "branches",
        modelValue: _ctx.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
        filterable: "",
        clearable: "",
        size: "large",
        disabled: _ctx.loading && _ctx.autoSelect,
        placeholder: _ctx.loading && _ctx.autoSelect ? 'Loading' : 'Select branches',
        class: "w-100",
        onClear: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('clear')))
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.preferredBranches, (branch) => {
            return (_openBlock(), _createBlock(_component_el_option, {
              key: branch.id,
              label: branch.name,
              value: branch.id
            }, null, 8, ["label", "value"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["modelValue", "disabled", "placeholder"])
    ]),
    _: 1
  }, 512))
}