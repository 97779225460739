
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
  watch
} from "vue";
import { Field } from "vee-validate";
import { useStore } from "vuex";

export interface PreferredBranch {
  id: number;
  name: string;
  jobs: number;
}

export default defineComponent({
  name: "preferred-branches-dropdown",
  components: { Field },
  props: {
    clientId: {
      type: [Number, null] as PropType<number | null>,
      default: () => null
    },
    autoSelect: {
      type: Boolean as PropType<boolean>,
      default: () => true
    }
  },
  emits: ["branch-selected", "clear"],
  setup: function(props, { emit }) {
    const value = ref<number|null>(null);
    const field = ref();
    const loading = ref<boolean>(false);
    const store = useStore();

    const preferredBranches = computed<PreferredBranch[]>(
      (): PreferredBranch[] => store.getters["ListsModule/preferredBranches"]
    );

    function setLoading(value: boolean): void {
      loading.value = value;
    }

    function fetchPreferredBranches(): void {
      setLoading(true);
      if (props.autoSelect) {
        value.value = null;
        field.value?.reset();
      }
      store
        .dispatch("ListsModule/fetchPreferredBranches", props.clientId)
        .then(() => {
          setLoading(false);
          if (
            props.autoSelect &&
            preferredBranches.value.length &&
            props.clientId
          ) {
            value.value = preferredBranches.value[0].id;
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }

    watch(
      () => value.value,
      () => {
        emit("branch-selected", [value.value]);
      }
    );

    watch(
      () => props.clientId,
      () => {
        fetchPreferredBranches();
      }
    );

    onMounted((): void => {
      fetchPreferredBranches();
    });

    function setValue(data: number): void {
      value.value = data;
    }

    return {
      preferredBranches,
      setValue,
      loading,
      value,
      field
    };
  }
});
