<template>
  <div class="card">
    <div class="card-body">
      <div class="d-flex flex-stack fs-4 py-3">
        <div class="fw-bolder rotate">
          Changes Summary
        </div>
      </div>
      <div class="separator"></div>
      <div id="kt_user_view_details" class="collapse show">
        <div class="pb-5 fs-6"></div>
      </div>
      <div class="d-flex justify-content-end mt-5 align-items-center">
        <!--<button class="btn btn-sm btn-primary" @click="$emit('template')">
          Save template
        </button>-->
        <button
          :disabled="updating"
          class="btn btn-sm btn-success"
          @click="$emit('save')"
        >
          <div
            v-if="updating"
            class="spinner-border spinner-border-sm"
            role="status"
          ></div>
          Save
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "changes-summary-card",
  computed: {
    ...mapGetters("EditJobModule", ["updating"])
  }
});
</script>
