<template>
  <AddNewShiftModal :client-id="selectedCompany"></AddNewShiftModal>
  <LoadingSpinner v-if="loading || Object.keys(job).length === 0"></LoadingSpinner>
  <div class="row" v-else>
    <div class="col-8">
      <div class="row">
        <div class="col-12">
          <SelectClientCard
            :auto-select-values="autoSelectValues"
            class="pe-6 mb-7"
            @selectCompany="handleSelectCompany"
            ref="selectClientsCard"
          />
        </div>
        <ConfigureShiftsCard class="col-12"></ConfigureShiftsCard>
        <EditJobDetailsCard
          :auto-select="autoSelectValues"
          :is-initialized="isInitialized"
          :company-id="selectedCompany"
          class="col-12"
          ref="details_card"
        ></EditJobDetailsCard>
      </div>
    </div>
    <div class="col-4">
      <div class="row">
        <SaveEditedCard @save="handleSaveChanges"></SaveEditedCard>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent } from 'vue'
import { mapActions, mapGetters} from 'vuex'
import EditJobDetailsCard from "@/components/jobs/Edit/EditJobDetails.vue"
import LoadingSpinner from "@/components/general/LoadingSpinner.vue";
import ConfigureShiftsCard from "@/components/jobs/Edit/ConfigureShifts.vue";
import AddNewShiftModal from "@/components/jobs/Edit/AddNewShiftModal.vue";
import SaveEditedCard from "@/components/jobs/Edit/SaveEditedCard.vue"
import SelectClientCard from "@/components/jobs/Create/SelectClientCard.vue";

export default defineComponent({
    name:"edit-job-index",
    components: {
      SelectClientCard,
        LoadingSpinner,
        EditJobDetailsCard,
        ConfigureShiftsCard,
        AddNewShiftModal,
        SaveEditedCard
    },
    data: () => ({
      autoSelectValues: false,
      isInitialized: false,
      selectedCompany: null
    }),
    methods: {
        ...mapActions("EditJobModule", ["update", "fetchJob", "resetState"]),
        ...mapActions("ListsModule", ["fetchPMs", "fetchSingleVenue"]),
        ...mapActions("JobCreateModule", ["fetchFrequentlyUsedSkills"]),
      handleSaveChanges: function() {
        this.$refs.selectClientsCard.validateForm()
          .then((clientCardData) => {
            this.$refs.details_card.validateInputs()
              .then((jobDetails) => {
                const data = {
                  ...clientCardData,
                  ...jobDetails
                };
                this.update(data);
              }).catch((error) => console.log(error));
          });
      },
      handleSelectCompany(companyId) {
          if(this.isInitialized) {
            this.autoSelectValues = true;
            this.selectedCompany = companyId;
          } else {
            this.isInitialized = true;
          }
      },
    },
    computed:{
        ...mapGetters("EditJobModule", ["loading", "job"]),
        ...mapGetters("ListsModule", ["countries"]),
    },
    mounted() {
        this.fetchJob(this.$route.params.id)
          .then(() => {
              this.$refs.selectClientsCard.setCompanyId(this.job.company.id)
              this.$refs.selectClientsCard.setInitialData({
                branches: this.job.book_from_branches.map(i => i.id),
              })
            this.fetchFrequentlyUsedSkills(this.job.company.id);
          })
        this.fetchPMs();
    },
    beforeUnmount() {
        this.resetState();
    }
})
</script>
