
/* eslint-disable @typescript-eslint/camelcase */
import CompaniesDropdown from "@/components/dropdown/VeeCompaniesDropdown.vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as Yup from "yup";
import {
  defineComponent,
  nextTick,
  onBeforeMount, onMounted,
  PropType,
  reactive,
  ref,
  watch
} from "vue";
import PreferredBranchesDropdown from "@/components/jobs/Misc/PreferredBranchesDropdown.vue";
import { useStore } from "vuex";
import { useAbility } from "@casl/vue";

export interface SelectClientCardData {
  company: number | null;
  branches: number[];
}

export default defineComponent({
  components: {
    PreferredBranchesDropdown,
    CompaniesDropdown,
    Form,
    ErrorMessage
  },
  emits: ["select-company", "select-branch"],
  name: "SelectClientCard",
  props: {
    autoSelectValues: {
      type: Boolean as PropType<boolean>,
      default: () => true
    }
  },
  setup: function(props, { emit }) {
    const formComponent = ref();
    const casl = useAbility();
    const store = useStore();
    const canSelectClient = ref<boolean>(true);
    const preferredBranchesDropdown = ref<
      InstanceType<typeof PreferredBranchesDropdown>
    >();
    const selectClientData = reactive<SelectClientCardData>({
      company: null,
      branches: []
    });

    const validationSchema = Yup.object().shape({
      company: Yup.number()
        .required()
        .typeError("Company is a required field.")
        .label("Company"),
      branches: Yup.number()
        .required()
        .typeError("Please select at least one branch.")
        .label("Branches")
    });

    function validateForm(): Promise<SelectClientCardData> {
      return new Promise<SelectClientCardData>((resolve, reject) => {
        formComponent.value?.validate().then(res => {
          if (res.valid) {
            resolve(selectClientData);
          } else {
            reject();
          }
        });
      });
    }

    function setClientFrequentSkills(): void {
      store.dispatch("JobCreateModule/clearPreviousFrequentSkills");
      store.dispatch(
        "JobCreateModule/fetchFrequentlyUsedSkills",
        selectClientData.company
      );
    }

    function resetFields(): void {
      formComponent.value.setFieldValue("branches", null);
    }

    watch(
      () => selectClientData.company,
      () => {
        if (props.autoSelectValues) {
          resetFields();
          setClientFrequentSkills();
        }
        emit("select-company", selectClientData.company ?? null);
      }
    );

    function handleSelectBranches(branches: number[]): void {
      selectClientData.branches = branches;
      formComponent.value.setFieldValue("branches", branches);
      emit("select-branch", branches);
      if (branches && branches.length > 0) {
        formComponent.value.setFieldError("branches", undefined);
      }
    }

    function handleClear(): void {
      formComponent.value.setFieldError(
        "branches",
        "Please select branch"
      );
    }

    function setInitialData(data): void {
      preferredBranchesDropdown.value?.setValue(
        data.branches ? data.branches[0] : null
      );
      formComponent.value.setFieldValue("branches", data.branches);
    }

    function setCompanyId(companyId: number): void {
      selectClientData.company = companyId;
      formComponent.value.setFieldValue("company", companyId);
    }

    onBeforeMount(() => {
      canSelectClient.value = !casl.can("view_only_own_company_jobs", "all");
    });

    onMounted((): void => {
      const currentUser = store.getters.currentUser;
      if (currentUser && currentUser.company_id) {
        formComponent.value?.setFieldValue("company", currentUser.company_id);
        selectClientData.company = currentUser.company_id;
      }
    });

    return {
      preferredBranchesDropdown,
      handleSelectBranches,
      selectClientData,
      canSelectClient,
      validationSchema,
      setInitialData,
      validateForm,
      formComponent,
      handleClear,
      setCompanyId
    };
  }
});
