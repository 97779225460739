<template>
  <EditJobIndex></EditJobIndex>
</template>

<script>
import { defineComponent } from "vue";
import EditJobIndex from "@/components/jobs/Edit/EditJobIndex.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "edit-job-view",
  components: {
    EditJobIndex
  },
  mounted() {
    setCurrentPageTitle("Edit job");
  }
});
</script>
