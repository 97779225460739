import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "card" }
const _hoisted_4 = { class: "card-header border-0 pt-5 px-6" }
const _hoisted_5 = { class: "card-title align-items-start flex-column" }
const _hoisted_6 = { class: "card-label fw-bolder fs-3 mb-1" }
const _hoisted_7 = { class: "text-muted mt-1 fw-bold fs-7" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "col-6" }
const _hoisted_10 = { class: "fv-plugins-message-container" }
const _hoisted_11 = { class: "fv-help-block" }
const _hoisted_12 = { class: "fv-plugins-message-container" }
const _hoisted_13 = { class: "fv-help-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CompaniesDropdown = _resolveComponent("CompaniesDropdown")!
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_PreferredBranchesDropdown = _resolveComponent("PreferredBranchesDropdown")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h3", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.canSelectClient ? 'Select Client'  : 'Select Branch'), 1),
            _createElementVNode("span", _hoisted_7, "Select the " + _toDisplayString(_ctx.canSelectClient ? 'client'  : 'branch') + " for whom you are creating the job", 1)
          ])
        ]),
        _createVNode(_component_Form, {
          "validation-schema": _ctx.validationSchema,
          ref: "formComponent",
          class: "card-body py-3 px-5"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_8, [
              _withDirectives(_createElementVNode("div", _hoisted_9, [
                (_ctx.canSelectClient)
                  ? (_openBlock(), _createBlock(_component_CompaniesDropdown, {
                      key: 0,
                      modelValue: _ctx.selectClientData.company,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectClientData.company) = $event)),
                      placeholder: "Select a company",
                      name: "company",
                      class: "w-100"
                    }, null, 8, ["modelValue"]))
                  : (_openBlock(), _createBlock(_component_Field, {
                      key: 1,
                      name: "company"
                    })),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _createVNode(_component_ErrorMessage, { name: "company" })
                  ])
                ])
              ], 512), [
                [_vShow, _ctx.canSelectClient]
              ]),
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.canSelectClient ? 'col-6' : 'col-12')
              }, [
                _createVNode(_component_PreferredBranchesDropdown, {
                  ref: "preferredBranchesDropdown",
                  onClear: _ctx.handleClear,
                  "auto-select": _ctx.autoSelectValues,
                  "client-id": _ctx.selectClientData.company,
                  onBranchSelected: _ctx.handleSelectBranches
                }, null, 8, ["onClear", "auto-select", "client-id", "onBranchSelected"]),
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, [
                    _createVNode(_component_ErrorMessage, { name: "branches" })
                  ])
                ])
              ], 2)
            ])
          ]),
          _: 1
        }, 8, ["validation-schema"])
      ])
    ])
  ]))
}