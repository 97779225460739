<template>
  <div class="row mt-7">
    <CreateNewColModal
      ref="createNewColModal"
      :key="modalKey"
      :company="selectedCompany"
      :in-job-edit="true"
      @success="handleCreatedCol"
    ></CreateNewColModal>
    <div class="col-12">
      <div class="card">
        <div class="card-header border-0 pt-5 px-6">
          <h3 class="card-title align-items-start flex-column">
            <span class="card-label fw-bolder fs-3 mb-1">Job Details</span>

            <span class="text-muted mt-1 fw-bold fs-7">Edit job's details</span>
          </h3>
        </div>

        <div class="card-body py-3 px-0 mt-2">
          <Form
            id="JobDetailsCardForm"
            ref="job_details_form"
            class="form"
            :validation-schema="validationSchema"
          >
            <div class="row mb-4 ms-3 me-3">
              <div class="col-6">
                <label class="required fs-6 fw-bold mb-2">Name</label>
                <Field
                  type="text"
                  class="form-control form-control-solid"
                  placeholder="Give your job a name"
                  name="name"
                  v-model="JobData.name"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="name" />
                  </div>
                </div>
              </div>
              <div class="col-6">
                <label class="required fs-6 fw-bold mb-2">City</label>
                <Field
                  type="text"
                  class="form-control form-control-solid"
                  placeholder="Enter city"
                  name="city"
                  v-model="JobData.city"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="city" />
                  </div>
                </div>
              </div>
              <div class="col-6 mt-4">
                <label class="fs-6 fw-bold mb-2">Country</label>
                <CountriesDropdown
                  ref="countriesDropdown"
                  :name="'country'"
                  :defaultValue="JobData.country"
                  @change="
                    id => {
                      JobData.country = id;
                    }
                  "
                ></CountriesDropdown>
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="country" />
                  </div>
                </div>
              </div>
              <div class="col-6 mt-4">
                <label class="fs-6 fw-bold mb-2">Entrance</label>
                <Field
                  type="text"
                  class="form-control form-control-solid"
                  placeholder="Enter entrance"
                  name="entrance"
                  v-model="JobData.entrance"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="entrance" />
                  </div>
                </div>
              </div>
              <div class="col-6 mt-4">
                <label class="required fs-6 fw-bold mb-2">Address</label>
                <Field
                  type="text"
                  class="form-control form-control-solid"
                  placeholder="Enter address"
                  name="address"
                  v-model="JobData.address"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="address" />
                  </div>
                </div>
              </div>
              <div class="col-6 mt-4">
                <label class="required fs-6 fw-bold mb-2">Venue</label>
                <PreferredVenuesDropdown
                  ref="preferredVenuesDropdown"
                  :auto-select="autoSelect"
                  :client-id="companyId"
                  @selected-venue="handleSelectedVenue"
                />
              </div>
              <div class="col-6 mt-4">
                <label class="fs-6 fw-bold mb-2">Invoice ref.</label>
                <Field
                  type="text"
                  class="form-control form-control-solid"
                  placeholder="Enter invoice reference"
                  name="invoice"
                  v-model="JobData.invoice"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="invoice" />
                  </div>
                </div>
              </div>
              <div class="col-6 mt-4">
                <label class="fs-6 fw-bold mb-2">Postal code</label>
                <Field
                  type="text"
                  class="form-control form-control-solid"
                  placeholder="Enter postal code"
                  name="postal"
                  v-model="JobData.postal"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="postal" />
                  </div>
                </div>
              </div>
              <div class="col-6 mt-4">
                <label class="fs-6 fw-bold mb-2">Area/District</label>
                <Field
                  type="text"
                  class="form-control form-control-solid"
                  placeholder="Enter area/district"
                  name="area"
                  v-model="JobData.area"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="area" />
                  </div>
                </div>
              </div>
              <div class="col-6 mt-4">
                <div class="w-100 d-flex flex-column">
                  <label class="fs-6 fw-bold mb-2">Job Dresscode</label>
                  <Field v-slot="{ field }" name="dresscode">
                    <el-select
                      v-model="JobData.dresscode"
                      v-bind="field"
                      placeholder="Select job dresscode"
                      size="large"
                      class="w-100"
                      clearable
                    >
                      <el-option
                        v-for="dresscode in dresscodes"
                        :key="dresscode.id"
                        :label="dresscode.name"
                        :value="dresscode.id"
                      />
                    </el-select>
                  </Field>
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="dresscode" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 mt-4">
                <label class="fs-6 fw-bold mb-2">Overall job description</label>
                <textarea
                  class="form-control form-control-solid"
                  placeholder="Please give us as many details for the job as you can"
                  rows="4"
                  v-model="JobData.details"
                ></textarea>
              </div>
              <div class="col-6 mt-4" v-if="!canEditPmAndCompany">
                <label class="fs-6 fw-bold mb-2"
                  >Select a crew handler</label
                >
                <CHDropdown
                  v-model="JobData.projectManager"
                  :defaultValue="JobData.projectManager"
                ></CHDropdown>
              </div>
              <div class="col-6 mt-4">
                <label class="fs-6 fw-bold mb-2">Contact on location</label>
                <ColsDropdown
                  @change_col="
                    id => {
                      JobData.colID = id;
                    }
                  "
                  :initialValue="JobData.colID"
                  @clear="JobData.colID = ''"
                  @create_new_col="handleCreateNewCol"
                ></ColsDropdown>
              </div>
              <div class="col-6 mt-4">
                <label class="fs-6 fw-bold mb-2">Upload files</label>
                <input
                  type="file"
                  class="form-control"
                  id="fileUpload"
                  aria-describedby="fileUploadField"
                  aria-label="Upload"
                />
              </div>
              <div
                class="col-6 mt-4"
                v-if="$can('can_select_job_booker', 'all')"
              >
                <label class="fs-6 fw-bold mb-2"
                  >Select a Booker for the job</label
                >
                <SearchJobBookerDropdown
                  ref="searchBookersDropdown"
                  @cleared="handleSelectJobBooker(null)"
                  @value-change="handleSelectJobBooker"
                />
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import SearchJobBookerDropdown from "@/components/dropdown/SearchJobBookerDropdown.vue";
import { defineComponent, nextTick } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import CountriesDropdown from "@/components/dropdown/CountriesDropdown.vue";
import ColsDropdown from "@/components/dropdown/ColsDropdown.vue";
import CreateNewColModal from "@/components/jobs/Create/Misc/CreateNewColModal.vue";
import {mapActions, mapGetters} from "vuex";
import { Modal } from "bootstrap";
import * as Yup from "yup";
import PreferredVenuesDropdown from "@/components/jobs/Misc/PreferredVenuesDropdown.vue";
import CHDropdown from "@/components/dropdown/CrewHandlersDropdown.vue";

export default defineComponent({
  name: "edit-job-details-card",
  components: {
    CHDropdown,
    PreferredVenuesDropdown,
    SearchJobBookerDropdown,
    ColsDropdown,
    CreateNewColModal,
    CountriesDropdown,
    ErrorMessage,
    Field,
    Form
  },
  props: {
    autoSelect: {
      type: Boolean,
      default: false
    },
    isInitialized: {
      type: Boolean,
      default: false
    },
    companyId: {
      type: [Number, null],
      default: null
    }
  },
  data() {
    return {
      JobData: {
        name: "",
        country: "",
        city: "",
        entrance: "",
        invoice: "",
        address: "",
        details: "",
        projectManager: "",
        postal: "",
        area: "",
        colID: "",
        venueId: "",
        dresscode: ""
      },
      modalKey: 0,
      branchesSelectErrorLabelIsShown: false,
      validationSchema: Yup.object().shape({
        name: Yup.string()
          .required()
          .label("Name"),
        city: Yup.string()
          .required()
          .label("City"),
        address: Yup.string()
          .required()
          .label("Address"),
      })
    };
  },
  computed: {
    ...mapGetters("ListsModule", ["venues", "countries", "dresscodes"]),
    ...mapGetters("EditJobModule", ["job"]),
    canEditPmAndCompany() {
      if(this.$can("job_create_can_edit_company", "all") || this.$can("job_create_can_edit_pm", "all")) {
        return false;
      } else {
        return true;
      }
    },
    bookerUiHandle() {
      if(!this.canEditPmAndCompany) {
        return "mt-4";
      } else {
        return "";
      }
    },
    venueId() {
      return this.JobData.venueId;
    },
    selectedCompany() {
      if(this.companyId) {
        return this.companyId;
      } else {
        return this.job.company.id;
      }
    }
  },
  watch: {
    venueId() {
      if(this.isInitialized) {
        this.fetchSingleVenue(this.JobData.venueId).then(venueData => {
          this.populateFieldsByVenue(
            venueData
          );
        });
      }
    }
  },
  methods: {
    ...mapActions("ListsModule", ["fetchCompanyCols", "clearCols", "fetchSingleVenue", "fetchDresscodes"]),
    handleClear() {
      this.clearCols();
    },
    handleSelectedVenue(venueId) {
      this.JobData.venueId = venueId;
    },
    async handleCreateNewCol() {
      this.modalKey++;
      await nextTick();
      this.modal = new Modal(document.getElementById('create_new_col_modal'));
      this.modal.show();
    },
    async handleCreatedCol(data) {
      await this.fetchCompanyCols(this.selectedCompany);
      this.JobData.colID = data.id;
      this.modal.hide();
    },
    handleSelectJobBooker(value) {
      this.JobData.bookerId = value;
    },
    validateInputs() {
    return new Promise((resolve, reject) => {
      this.$refs.job_details_form.validate();
      this.validationSchema.isValid(this.JobData).then(validationResult => {
        if (validationResult) {
          this.branchesSelectErrorLabelIsShown = false;
          resolve(this.JobData);
        }else {
          reject(new Error('Invalid job details'));
        }
        })
      });
    },
    populateFieldsByVenue(selectedVenue) {
      this.JobData.city = selectedVenue.city;
      this.JobData.country = selectedVenue.country ? selectedVenue.country.id : null;
      this.JobData.entrance = selectedVenue.entrance;
      this.JobData.address = selectedVenue.address;
      this.JobData.postal = selectedVenue.zip;
      this.JobData.area = selectedVenue.province;
    },
  },
  mounted() {
    if(this.job.booker && this.$can("can_select_job_booker", 'all')) {
      this.JobData.bookerId = this.job.booker.id;
      this.$refs.searchBookersDropdown.setInitialData(this.job.booker);
    }
    this.fetchDresscodes();
    if(this.job.venue) {
      this.JobData.venueId = this.job.venue.id;
      this.$refs.preferredVenuesDropdown.setValue(this.job.venue.id)
    }
  },
  beforeMount() {
      this.fetchCompanyCols(this.selectedCompany);
      this.JobData.name = this.job.name;
      this.JobData.details = this.job.details
      this.JobData.invoice = this.job.invoice_reference ? this.job.invoice_reference : ""
      this.JobData.projectManager = this.job.project_manager ? this.job.project_manager.id : ""
      this.JobData.colID = this.job.contact_on_location ? this.job.contact_on_location.id : ""
      this.JobData.address = this.job.address;
      this.JobData.city = this.job.city;
      this.JobData.area = this.job.province;
      this.JobData.entrance = this.job.entrance;
      this.JobData.dresscode = this.job.dresscode ? this.job.dresscode.id : '';
  }
});
</script>
